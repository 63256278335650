import ConfigProvider from 'antd/es/config-provider';
import * as React from 'react';

import { COLORS } from '@/shared/config/colors';

export const ConfigUiProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextBase: COLORS.colorTextBase,
        },
        components: {
          Typography: {
            titleMarginTop: 0,
            titleMarginBottom: 0,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
