import { ApiStore } from '@/shared/store/ApiStore';
import { ILocalStore } from '@/shared/store/interfaces/ILocalStore';

import { UserStore } from './UserStore';

export class RootStore implements ILocalStore {
  readonly apiStore = new ApiStore();
  readonly userStore = new UserStore();

  destroy() {
    this.userStore.destroy();
  }
}
