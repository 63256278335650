import { Method } from 'axios';

import { IApiStore } from '@/shared/store/interfaces/IApiStore';
import { ApiOptions, ApiResponseType } from '@/shared/types/Api';
import { api } from '@/shared/utils/api';

export class ApiStore implements IApiStore {
  async call<ApiData>(
    endpoint: string,
    methodType: Method = 'GET',
    data: Record<string, any> = {},
    options: ApiOptions = {},
  ): Promise<ApiResponseType<ApiData>> {
    return await api(endpoint, methodType, data, options);
  }
}
