import { action, computed, makeObservable, observable } from 'mobx';

import { Profile, ProfileModel } from '@/shared/models/user';
import { MetaStore } from '@/shared/store/MetaStore';
import { ILocalStore } from '@/shared/store/interfaces/ILocalStore';
import { sleep } from '@/shared/utils/sleep';

import { UserShopsStore } from './UserShopsStore';

/**
 * Глобальный стор текущего пользователя
 */
export class UserStore implements ILocalStore {
  readonly authMeta = new MetaStore();
  readonly loadProfileMeta = new MetaStore();

  readonly userShops = new UserShopsStore();

  currentUser: ProfileModel | null = null;

  constructor() {
    makeObservable(this, {
      currentUser: observable.ref,
      isAuth: computed,
      setUser: action.bound,
      loadProfile: action.bound,
    });

    this.loadProfile();
  }

  /**
   * Авторизован ли пользователь
   */
  get isAuth(): boolean {
    return this.currentUser !== null;
  }

  /**
   * Инициализация пользователя
   */
  setUser(user: Profile): void {
    this.currentUser = new ProfileModel(user);
  }

  init = async (): Promise<void> => {
    await Promise.all([this.loadProfile(), this.userShops.loadMerchantInfo()]);
  };

  /**
   * Загрузка профиля пользователя
   */
  async loadProfile(): Promise<void> {
    this.loadProfileMeta.setLoadedStartMeta();
    await sleep(500);

    const _MOCK_USER: Profile = {
      name: 'Андрей',
      phone: '+79111234567',
      email: 'test@mail.ru',
      subscription: {
        status: 'Пробный период',
        email: '1234@mail.ru',
      },
    };

    this.setUser(_MOCK_USER);
    this.authMeta.setLoadedSuccessMeta();
    this.loadProfileMeta.setLoadedSuccessMeta();
  }

  destroy(): void {
    this.authMeta.destroy();
    this.loadProfileMeta.destroy();
  }
}
