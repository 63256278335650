import { Profile, Subscription } from './types';

export class ProfileModel {
  readonly name: Profile['name'];
  readonly phone: Profile['phone'];
  readonly email: Profile['phone'];
  readonly subscription: Subscription;

  constructor(initialData: Profile) {
    this.name = initialData.name;
    this.phone = initialData.phone;
    this.email = initialData.email;
    this.subscription = initialData.subscription;
  }
}
