import { combineErrors } from './combineErrors';
import { ErrorType, Validator } from './types';

const MSG_REQUIRED_FIELD = 'Обязательное поле';
const MSG_INCORRECT_FIELD = 'Поле заполнено некорректно';

export const getErrorWithMessage = (text: string): ErrorType => {
  return {
    value: true,
    text,
  };
};

export const getEmptyError = (value = false): ErrorType => {
  return { value, text: '' };
};

// Валидирует через все валидаторы и возвращает все ошибки
export const composeValidators =
  <V>(...funcs: Validator<V>[]): Validator<V> =>
  (v: V): ErrorType =>
    funcs.reduce<ErrorType>(
      (acc, validator) => combineErrors(acc, validator(v)),
      getEmptyError(),
    );

// Валидирует поочередно и возвращает первую ошибку
export const orderValidators =
  <V>(...funcs: Validator<V>[]): Validator<V> =>
  (v: V): ErrorType => {
    for (const validator of funcs) {
      const result = validator(v);

      if (result.value) {
        return result;
      }
    }

    return getEmptyError();
  };

export const validateNotEmpty =
  <T>(errorText: string = MSG_REQUIRED_FIELD): Validator<T> =>
  (v?: T | null): ErrorType => {
    return v === undefined || v === null || !String(v).trim().length
      ? getErrorWithMessage(errorText)
      : getEmptyError();
  };

export const requireIf =
  <T>(
    condition: () => boolean,
    errorText: string = MSG_REQUIRED_FIELD,
  ): Validator<T> =>
  (v?: T): ErrorType => {
    if (!condition()) {
      return getEmptyError();
    }

    return validateNotEmpty(errorText)(v);
  };

export const validateIf =
  <T>(
    condition: () => boolean, // условие, при котором мы проходим валидацию
    validator: Validator<T>,
  ): Validator<T> =>
  (value: T): ErrorType => {
    if (condition()) {
      return validator(value);
    }

    return getEmptyError();
  };

export const customValidate =
  <T>(
    condition: (v?: T) => boolean,
    error: string | ((v?: T) => string) = MSG_INCORRECT_FIELD,
  ): Validator<T> =>
  (v?: T): ErrorType => {
    const errorText = typeof error === 'function' ? error(v) : error;

    return !condition(v)
      ? {
          value: true,
          text: errorText,
        }
      : getEmptyError();
  };
