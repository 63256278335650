import * as React from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider as BaseRouterProvider,
} from 'react-router-dom';

import { ROUTES } from '@/shared/config/routes';

const ProductsPage = React.lazy(() => import('@/pages/ProductsPage'));
const RegistrationShopPage = React.lazy(
  () => import('@/pages/RegistrationShopPage'),
);

const router = createBrowserRouter([
  {
    path: ROUTES.products,
    element: <ProductsPage />,
  },
  {
    path: ROUTES.registration,
    element: <RegistrationShopPage />,
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.products} replace />,
  },
]);

export const RouterProvider = () => {
  return (
    <React.Suspense>
      <BaseRouterProvider router={router} />
    </React.Suspense>
  );
};
