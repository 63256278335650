import * as React from 'react';

import { useLocalStore } from '@/shared/hooks/useLocalStore';
import { useStrictContext } from '@/shared/hooks/useStrictContext';
import { createStrictContext } from '@/shared/utils/createStrictContext';

import { RootStore } from './RootStore';
import { UserStore } from './UserStore';

const RootStoreContext = createStrictContext<RootStore>();

type RootStoreProviderProps = {
  children: React.ReactNode;
};

export const RootStoreProvider: React.FC<RootStoreProviderProps> = ({
  children,
}) => {
  const store = useLocalStore(() => new RootStore());

  return (
    <RootStoreContext.Provider value={store}>
      {children}
    </RootStoreContext.Provider>
  );
};

export const useRootStore = () =>
  useStrictContext({
    context: RootStoreContext,
    message: 'useRootStore is not in RootStoreProvider',
  });

export const useUserStore = (): UserStore => {
  return useRootStore().userStore;
};

export const useHasShops = (): boolean => {
  return !useRootStore().userStore.userShops.collection.isEmpty;
};
