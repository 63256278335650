import { action, computed, makeObservable, observable } from 'mobx';

import { ILocalStore } from '../interfaces/ILocalStore';

type PrivateFields = '_order' | '_entities';

export type CollectionParams<Entity, Raw, Key extends KeyType = string> = {
  parseItem: (item: Raw) => Entity;
  getEntityKey: (entity: Entity) => Key;
};

/**
 * Тип данных ключа
 */
export type KeyType = string | number;

/**
 * Тип данных коллекция
 */
export class Collection<Entity, Raw, Key extends KeyType = string>
  implements ILocalStore
{
  /**
   * Порядок следования элементов (массив айдишников)
   */
  private _order: Key[] = [];

  /**
   * объект с сущностями
   */
  private _entities: Record<Key, Entity> = {} as Record<Key, Entity>;

  /**
   * Функция парсинга данных
   */
  readonly parseItem: (item: Raw) => Entity;

  readonly getEntityKey: (entity: Entity) => Key;

  constructor(params: CollectionParams<Entity, Raw, Key>) {
    this.parseItem = params.parseItem;
    this.getEntityKey = params.getEntityKey;

    makeObservable<Collection<Entity, Raw, Key>, PrivateFields>(this, {
      _order: observable.ref,
      _entities: observable.ref,
      fromArray: action.bound,

      order: computed,
      entities: computed,
      idsSet: computed,
      isEmpty: computed,
      size: computed,
      items: computed,

      setOrder: action.bound,
      setEntities: action.bound,
      updateItemByKey: action.bound,
      deleteById: action.bound,
      push: action.bound,
      unshift: action.bound,
      destroy: action.bound,
    });
  }

  get items(): Entity[] {
    return this.order.map((id) => this.entities[id]);
  }

  get size(): number {
    return this._order.length;
  }

  /**
   * Является ли коллекция пустой
   */
  get isEmpty(): boolean {
    return this.size === 0;
  }

  get order(): Key[] {
    return this._order;
  }

  get entities(): Record<Key, Entity> {
    return this._entities;
  }

  get idsSet(): Set<string> {
    return new Set(Object.keys(this.entities));
  }

  find(predicate: (value: Entity) => boolean): Entity | null {
    for (const id of this._order) {
      const entity = this.entities[id];

      if (predicate(entity)) {
        return entity;
      }
    }

    return null;
  }

  map<N>(callback: (element: Entity, index: number) => N): N[] {
    return this.order.map((id, index) => {
      const entity: Entity = this.entities[id];

      return callback(entity, index);
    });
  }

  forEach(callback: (element: Entity) => void): void {
    this.order.forEach((id) => {
      const entity = this.entities[id];

      callback(entity);
    });
  }

  setOrder(newOrder: Key[]): void {
    this._order = newOrder;
  }

  setEntities(newEntities: Record<Key, Entity>): void {
    this._entities = newEntities;
  }

  fromArray = (items: Raw[]): void => {
    const newOrder: Key[] = [];

    const newEntities = items.reduce<Record<Key, Entity>>(
      (acc, next) => {
        const parsedItem = this.parseItem(next);

        const id = this.getEntityKey(parsedItem);

        acc[id] = parsedItem;
        newOrder.push(id);

        return acc;
      },
      {} as Record<Key, Entity>,
    );

    this.setOrder(newOrder);
    this.setEntities(newEntities);
  };

  getItemByKey(id: Key): Entity | null {
    return this.entities[id] || null;
  }

  updateItemByKey(key: Key, entity: Entity): void {
    if (this._entities[key] === undefined) {
      return;
    }

    this.setEntities({
      ...this._entities,
      [key]: entity,
    });
  }

  getItemByIndex(index: number): Entity | null {
    const key = this.order[index];

    if (key === undefined) {
      return null;
    }

    return this.getItemByKey(key);
  }

  /**
   * Удаление данных из коллекции
   * @param id
   */
  deleteById(id: Key): Entity {
    const newOrder = this.order.filter((key) => key !== id);

    const newItems = { ...this.entities };

    const result = newItems[id];

    delete newItems[id];

    this.setEntities(newItems);
    this.setOrder(newOrder);

    return result;
  }

  /**
   * Добавление данных в коллекцию
   * @param element
   */
  push(element: Entity): void {
    const id = this.getEntityKey(element);

    this.setEntities({ ...this.entities, [id]: element });
    this.setOrder([...this.order, id]);
  }

  unshift(element: Entity): void {
    const id = this.getEntityKey(element);

    this.setEntities({ [id]: element, ...this.entities });
    this.setOrder([id, ...this.order]);
  }

  destroy() {
    // @ts-ignore
    // Дестроим элементы
    this.forEach((elem) => elem.destroy?.());
    this.setEntities({} as Record<Key, Entity>);
    this.setOrder([]);
  }
}
