import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import { Merchant, MerchantSchema } from '@/entities/Merchant';
import { API_URLS } from '@/shared/config/apiUrls';
import { ApiStore } from '@/shared/store/ApiStore';
import { Collection } from '@/shared/store/Collection';
import { MetaStore } from '@/shared/store/MetaStore';
import { ILocalStore } from '@/shared/store/interfaces/ILocalStore';

/**
 * Глобальный стор магазинов пользователя
 */
export class UserShopsStore implements ILocalStore {
  private readonly _apiStore = new ApiStore();
  readonly meta = new MetaStore();
  readonly collection: Collection<Merchant, Merchant> = new Collection({
    getEntityKey: (e) => String(e.id),
    parseItem: (e) => MerchantSchema.parse(e),
  });
  currentMerchant: Merchant;

  constructor() {
    // TODO: снести моковый магазин, когда отладим флоуы
    this.collection.push({
      id: '123123',
      name: 'Zecar',
      minPriceConstraint: 0,
      maxPriceConstraint: 1_000_000_000,
      isActive: true,
      isPreOrderAllowed: true,
      isProductsConstructorEnabled: true,
    });
    this.currentMerchant = this.collection.items[0];

    makeObservable(this, {
      currentMerchant: observable.ref,
      currentMerchantName: computed,
      setCurrentMerchant: action.bound,
    });
  }

  get currentMerchantName(): string {
    return this.currentMerchant.name || this.currentMerchant.id;
  }

  setCurrentMerchant(id: string) {
    const merchant = this.collection.getItemByKey(id);

    if (merchant) {
      this.currentMerchant = merchant;
    }
  }

  /**
   * Загрузка магазинов пользователя
   */
  async loadMerchantInfo() {
    this.meta.setLoadedStartMeta();

    const { response, isError } = await this._apiStore.call<{
      items: Merchant[];
    }>(API_URLS.merchant.root);

    if (isError) {
      this.meta.setLoadedErrorMeta();

      return;
    }

    runInAction(() => {
      this.collection.fromArray(response.items);
      this.meta.setLoadedSuccessMeta();
    });
  }

  destroy(): void {
    this.collection.destroy();
    this.meta.destroy();
  }
}
