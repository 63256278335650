export const initVh = () => {
  const update = () => {
    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--live-vh', `${vh}px`);

    const vw = window.innerWidth * 0.01;

    document.documentElement.style.setProperty('--live-vw', `${vw}px`);
  };

  const vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty('--initial-vh', `${vh}px`);

  const vw = window.innerWidth * 0.01;

  document.documentElement.style.setProperty('--initial-vw', `${vw}px`);

  window.addEventListener('resize', update);
  update();
};
