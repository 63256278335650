import { z } from 'zod';

export const MerchantSchema = z.object({
  id: z.string(),
  name: z.string().nullish(),
  minPriceConstraint: z.number(),
  maxPriceConstraint: z.number(),
  isActive: z.boolean(),
  isPreOrderAllowed: z.boolean(),
  isProductsConstructorEnabled: z.boolean(),
});

export type Merchant = z.infer<typeof MerchantSchema>;
