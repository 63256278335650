import { RootStoreProvider } from '@/entities/RootStore';
import { WidthProvider } from '@/shared/utils/devices';

import { ConfigUiProvider } from './providers/ConfigUiProvider';
import { RouterProvider } from './providers/RouterProvider';

export const App = () => (
  <RootStoreProvider>
    <ConfigUiProvider>
      <WidthProvider>
        <RouterProvider />
      </WidthProvider>
    </ConfigUiProvider>
  </RootStoreProvider>
);
