import * as React from 'react';

export const DEVICES = {
  phone: {
    maxWidth: 767,
    minWidth: 0,
  },
  tablet: {
    maxWidth: 1024,
    minWidth: 768,
  },
  isPhone: (width: number): boolean =>
    width <= DEVICES.phone.maxWidth && width >= DEVICES.phone.minWidth,
  isTablet: (width: number): boolean =>
    width <= DEVICES.tablet.maxWidth && width >= DEVICES.tablet.minWidth,
  isDesktop: (width: number): boolean => width > DEVICES.tablet.maxWidth,
};

const useSubscribeToWidth = (hasWidth = false): number => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const unmounted = React.useRef(false);

  React.useEffect(() => {
    if (hasWidth) {
      return;
    }

    const handleResize = (): void => {
      if (unmounted.current) {
        return;
      }

      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return (): void => {
      unmounted.current = true;
      window.removeEventListener('resize', handleResize);
    };
  }, [hasWidth]);

  return width;
};

type WidthContextParams = {
  width: number | null;
};

const initialValue: WidthContextParams = {
  width: null,
};

const WidthContext = React.createContext<WidthContextParams>(initialValue);

type WidthProviderProps = {
  children?: React.ReactNode;
};

export const WidthProvider: React.FC<WidthProviderProps> = ({
  children,
}: WidthProviderProps) => {
  const width = useSubscribeToWidth();

  return (
    <WidthContext.Provider value={{ width }}>{children}</WidthContext.Provider>
  );
};

export const useWidth = (): {
  width: number;
  isPhone: boolean;
  isTablet: boolean;
  isDesktop: boolean;
} => {
  const { width: contextWidth } = React.useContext(WidthContext);

  // Если не удалось получить ширину из контекста, подписываемся в этом компоненте
  const subscribedWidth = useSubscribeToWidth(contextWidth !== null);

  const width = contextWidth !== null ? contextWidth : subscribedWidth;

  const isPhone = DEVICES.isPhone(width);
  const isTablet = DEVICES.isTablet(width);
  const isDesktop = DEVICES.isDesktop(width);

  return { width, isPhone, isTablet, isDesktop };
};
