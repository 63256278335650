import { action, computed, makeObservable, observable } from 'mobx';

import { ILocalStore } from '@/shared/store/interfaces/ILocalStore';
import { ErrorType } from '@/shared/utils/validators/types';
import { getErrorWithMessage } from '@/shared/utils/validators/validators';

import {
  getInitialMetaState,
  getLoadedErrorMeta,
  getLoadedSuccessMeta,
  getLoadingStartMeta,
  isInitialMetaState,
  Meta,
} from './utils';

type PrivateFields = '_data';

/**
 * Mobx класс для флажков isLoading и isError, Возможно потом будет расширяться
 **/
export class MetaStore implements ILocalStore {
  private _data: Meta;

  constructor(meta?: Meta) {
    this._data = meta || getInitialMetaState();

    makeObservable<MetaStore, PrivateFields>(this, {
      _data: observable.ref,
      isLoading: computed,
      isError: computed,
      isLoaded: computed,
      isInitialMetaState: computed,
      setLoadedStartMeta: action.bound,
      setLoadedSuccessMeta: action.bound,
      setLoadedErrorMeta: action.bound,
      reset: action.bound,
    });
  }

  get isLoading(): boolean {
    return this._data.isLoading;
  }

  get isError(): boolean {
    return this._data.isError;
  }

  get isInitialMetaState(): boolean {
    return isInitialMetaState(this._data);
  }

  get isLoaded(): boolean {
    return this._data.isLoaded;
  }

  get isStateLoaded(): boolean {
    return this.isLoaded || this.isError;
  }

  get error(): ErrorType | null {
    return this._data.error ?? null;
  }

  reset(): void {
    this._data = getInitialMetaState();
  }

  setLoadedStartMeta(): void {
    this._data = getLoadingStartMeta();
  }

  setLoadedSuccessMeta(): void {
    this._data = getLoadedSuccessMeta();
  }

  setLoadedErrorMeta(error?: ErrorType | string): void {
    if (typeof error === 'string') {
      this._data = getLoadedErrorMeta(getErrorWithMessage(error));

      return;
    }
    this._data = getLoadedErrorMeta(error);
  }

  static merge = (...items: MetaStore[]): MetaStore => {
    let data = getInitialMetaState();

    if (items.some((item) => item.isError)) {
      data = getLoadedErrorMeta();
    }

    if (items.some((item) => item.isLoading)) {
      data = getLoadingStartMeta();
    }

    if (items.every((item) => item.isLoaded)) {
      data = getLoadedSuccessMeta();
    }

    return new MetaStore(data);
  };

  destroy(): void {
    // nothing to do
  }
}
