import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from 'axios';
import queryString from 'query-string';

import { ApiOptions, ApiResponseType } from '@/shared/types/Api';
import { log } from '@/shared/utils/log';

const baseURL = import.meta.env.API_URL;

async function callApi<ApiData>(
  url: string,
  methodType: Method = 'GET',
  config: AxiosRequestConfig = {},
): Promise<AxiosResponse<ApiData>> {
  const result: AxiosResponse<ApiData> = await axios({
    withCredentials: true,
    method: methodType,
    url,
    baseURL,
    ...config,
  });

  return result;
}

export async function api<ApiData>(
  endpoint: string,
  methodType: Method = 'GET',
  data: Record<string, any> = {},
  options?: ApiOptions,
): Promise<ApiResponseType<ApiData>> {
  const multipartFormData = options?.multipartFormData || false;
  const config = options?.config || {};

  const queryConfig = { ...config };

  if (
    (queryConfig.data === null || queryConfig.data === undefined) &&
    methodType !== 'GET'
  ) {
    queryConfig.data = data;
  }

  if (!queryConfig.headers) {
    queryConfig.headers = {};
  }

  if (multipartFormData) {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, value);
      }
    });
    queryConfig.data = formData;

    Object.assign(queryConfig.headers, {
      'Content-Type': 'multipart/form-data',
    });
  }

  if (methodType === 'GET') {
    queryConfig.params = {
      ...data,
    };
    queryConfig.paramsSerializer = (params) =>
      queryString.stringify(params, { skipNull: true, arrayFormat: 'comma' });
  }

  try {
    const printParams = JSON.stringify(queryConfig.params);

    log(`[API]: ${methodType} ${endpoint} ${printParams} BEGIN`);
    const now = Date.now();
    const result = await callApi<ApiData>(endpoint, methodType, queryConfig);
    const response: ApiData = result.data;

    log(
      `[API]: ${methodType} ${endpoint} ${printParams} END ${(Date.now() - now) / 1000}`,
    );

    return { response, isError: false, headers: result.headers };
  } catch (err) {
    const error = err as AxiosError;

    const errorStatus = error?.response?.status;
    const errorData = error.response?.data || {};

    log(`[API]: ${methodType} ${endpoint} END ERROR ${errorStatus}`);

    return {
      response: null,
      isError: true,
      error,
      errorData,
      errorStatus,
      headers: error.response?.headers || {},
    };
  }
}
