const createApiPrefix = (endpoint: string): string => `/api/v1/${endpoint}`;
const createMerchantsPrefix = (endpoint: string): string =>
  createApiPrefix(`merchants/${endpoint}`);

export const API_URLS = {
  merchant: {
    root: createApiPrefix('merchants'),
    products: (id: string | number) =>
      createMerchantsPrefix(`${id}/products:search`),
  },
};
